import { MetricaPluginAdapter } from '@/composables/useMetrica';
import config from '@/config';
import { isOffline } from '@/helpers/network';

export interface AppMetricaLocation {
  latitude: number;
  longitude: number;
  altitude?: number;
  accuracy?: number;
  verticalAccuracy?: number;
  course?: number;
  speed?: number;
  timestamp?: number;
}

export interface AppMetricaActivateConfig {
  /** GUID-like API key from AppMetrica web interface. */
  apiKey: string;

  /**
   * First app launch with the AppMetrica SDK should be treated
   * as the first launch of the updated app version, and not as
   * the very first launch. Default: false.
   */
  handleFirstActivationAsUpdate?: boolean;

  /**
   * Allow or forbid the library to send device location data in reports.
   * Default: true.
   */
  locationTracking?: boolean;

  /** Session timeout in seconds. Default: 10. */
  sessionTimeout?: number;

  /** Monitor app crashes. Default: true. */
  crashReporting?: boolean;

  /** Custom app version. Default: null. */
  appVersion?: string;

  /** Logging. Default: false. */
  logs?: boolean;

  /** Custom location (default: null): */
  location?: AppMetricaLocation;

  preloadInfo?: {
    trackingId: string;
    additionalParams: Record<string, any>;
  };
}

export declare class AppMetrica {
  /**
   * Activates AppMetrica with API key and configuration.
   * 
   * @param config 
   */
  activate(config: AppMetricaActivateConfig): void;

  /**
   * Reports event with name and parameters.
   * 
   * @param eventName Short name or description of the event.
   * @param params Object of name/value pairs that should be sent to the server.
   */
  reportEvent(eventName: string, params?: Record<string, any>): void;

  /**
   * Reports error with name and reason.
   * 
   * @param errorName Short name or description of the error.
   * @param reason Reason of the error.
   */
  reportError(errorName: string, reason?: string): void;

  /**
   * Custom geolocation of device.
   * @param location Custom device location to be reported.
   */
  setLocation(location: AppMetricaLocation): void;

  /**
   * Enable/disable location reporting to AppMetrica.
   * @param enabled Flag indicating if reporting location to AppMetrica enabled.
   */
  setLocationTracking(enabled: boolean): void;
}

/**
 * Проверяет подключена ли App Метрика
 * @returns 
 */
export function hasAppMetrica(): boolean {
  return !!(window as any).appMetrica;
}

/**
 * Получает объект для работы с App Метрикой
 * 
 * @returns 
 */
export function getAppMetricaInstance(): AppMetrica {
  const appMetrica = (window as any).appMetrica;

  if (!appMetrica) {
    throw new Error('Не найден экземпляр яндекс метрики');
  }

  return appMetrica;
}

export const AppMetricaAdapter: MetricaPluginAdapter = {
  init({ onEvent }) {
    if (!hasAppMetrica()) return;

    if (!config.appMetricaApiKey) {
      console.error('Не установлен ключ для App Метрики');
      return;
    }

    const appMetrica = getAppMetricaInstance();

    appMetrica.activate({
      apiKey: config.appMetricaApiKey,
    });

    onEvent(ev => {
      if (isOffline()) return;
      appMetrica.reportEvent(ev.name, ev.params);
    });
  }
};