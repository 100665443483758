import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_c_infinite_calendar = _resolveComponent("c-infinite-calendar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, {
      class: _normalizeClass({
    'c-clean-close-header': true,
    'c-choice-between-date-modal-header': true,
    'c-choice-between-date-modal-header--hide': _ctx.headerHide,
  })
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_button, {
              class: "d-close-button",
              color: "light",
              fill: "clear",
              onClick: _ctx.close
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, { name: "core-close-backgroup" })
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["class"]),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_c_infinite_calendar, {
          onCalendarScroll: _ctx.onCalendarScroll,
          "is-beetween": true,
          modelValue: _ctx.dateBetween,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateBetween) = $event)),
          class: "visits-new-preentry-calendar"
        }, null, 8, ["onCalendarScroll", "modelValue"])
      ]),
      _: 1
    }),
    _createVNode(_component_ion_footer)
  ], 64))
}