
import { computed, defineComponent, PropType, ref, watch } from 'vue'
export default defineComponent({
  emits: [
    'update:modelValue',
    'max-limit', 'min-limit',
    'increment',  'decrement'
  ],

  props: {
    modelValue: {
      type: [Number, String] as PropType<number|string>,
      default: 0,
    },
    step: {
      type: Number as PropType<number>,
      default: 1,
    },
    min: {
      type: Number as PropType<number>,
      default: Number.MIN_VALUE,
    },
    max: {
      type: Number as PropType<number>,
      default: Number.MAX_VALUE,
    },
    isInput: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  setup(props, { emit }) {
    const intValue = ref(+props.modelValue);
    watch(() => props.modelValue, newVal => {
      if (newVal > props.max) {
        intValue.value = props.max;
        emit("max-limit", newVal);
      } else if (newVal < props.min) {
        intValue.value = props.min;
        emit("min-limit", newVal);
      } else {
        intValue.value = +newVal;
      }
    });

    const upDisabled = computed(() => (intValue.value >= props.max));
    const downDisabled = computed(() => (intValue.value <= props.min));

    function increment() {
      if (intValue.value + props.step > props.max) {
        emit("max-limit", intValue.value + props.step);
      } else {
        intValue.value += props.step;
        emit("increment", intValue.value);
        emit("update:modelValue", intValue.value);
      }
    }

    function decrement() {
      if (intValue.value - props.step < props.min) {
        emit("min-limit", intValue.value - props.step);
      } else {
        intValue.value -= props.step;
        emit("decrement", intValue.value);
        emit("update:modelValue", intValue.value);
      }
    }

    return {
      intValue,
      upDisabled,
      downDisabled,
      increment,
      decrement,
    };
  },
});
