import { createApp } from 'vue';
import { IonicVue, isPlatform } from '@ionic/vue';
import { IonicConfig } from '@ionic/core';
import { StatusBar, Style as StatusBarStyle } from '@capacitor/status-bar';
import '@capacitor-community/camera-preview';
import '@/service-worker/register';

import App from './App.vue';
import GlobalComponnets from './components/global';
import './icons';
import './ext/openKeyboard';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Swiper */
import 'swiper/scss';
import 'swiper/scss/free-mode';
import '@ionic/vue/css/ionic-swiper.css';

/** App styles */
import './scss/main.scss';

import { addBodyLegacyClasses } from '@/helpers/fixIOS';
import { startIntervalRRT } from '@/helpers/network';
import router from '@/router';
import { i18n } from '@/localization';
import AppContext from '@/composables/useApp';
import AppEvents from '@/composables/onEventsApp';
import AppNativeHandlers from '@/ext/nativeHandlers';
import Push from '@/composables/push';
import { MetricaPlugin } from '@/composables/useMetrica';
import { AppMetricaAdapter } from '@/ext/appmetrica';
import { DevLogMetricaAdapter } from '@/ext/devlogmetrica';
import { YandexMetricaAdapter } from '@/ext/yandexmetrica';
import { deviceReady } from '@/helpers/deviceready';
import { lockOrientation } from './helpers/orientation';

addBodyLegacyClasses();
startIntervalRRT();
StatusBar.setBackgroundColor({ color: '#0F1017' }); // Android
StatusBar.setStyle({ style: StatusBarStyle.Dark }); // iOS

const ionicVueConfig: IonicConfig = {
  mode: 'ios',
  hardwareBackButton: true,
  swipeBackEnabled: true,
  backButtonText: '',
};

const app = createApp(App, {})
  .use(AppContext)
  .use(AppEvents)
  .use(AppNativeHandlers)
  .use(MetricaPlugin, { adapters: [AppMetricaAdapter, DevLogMetricaAdapter, YandexMetricaAdapter] })
  .use(Push)
  .use(IonicVue, ionicVueConfig)
  .use(GlobalComponnets)
  .use(router)
  .use(i18n);

// fix: Если не подождать инициализации, то некоторые иконки не будут отображаться,
// т.к. плагин cordova-plugin-wkwebview-file-xhr будет еще не инициализирован
deviceReady(() => {
  router.isReady().then(() => {
    app.mount('#app');
  });

  // Для мобильных устройств приложение работает только в портретной ориентации
  if (isPlatform('mobile') && isPlatform('capacitor')) {
    lockOrientation('portrait');
  }
});
