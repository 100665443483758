import { AppDB } from '@/database/AppDB';
import { Response, Repositories } from '@/repositories';
import { cacheQuery, cacheQueryWithBloking } from '@/helpers/cache';
import { isOnline } from '@/helpers/network';
import { Ref, ComputedRef, toRaw, isRef } from 'vue';
import { cloneDeep } from 'lodash';
import { isComputed } from '@/helpers/vue';
import { ClientInstance } from '@/repositories';

export interface BaseStoreContext {
  db: AppDB;
  repositories: Repositories;
  http: ClientInstance; 
}

export interface OperationInfo<T = any, E = number|string> {
  /** true - операция была отложена, до момента подключения к сети */
  sendingDelayed: boolean;

  /** В случае если есть соединение с интернетом и запрос был отправлен сразу  */
  response?: Response<T>;

  /** Запрос уже существовал и была произведена операция по слиянию значений */
  isMerge?: boolean;

  /** Идентификатор сущности по которому к ней можно обратиться */
  entityId: E;
}

export interface QueryCacheOptions {
  // Время жизни кэша в наносекундах
  relevantTime?: number;
}

export abstract class BaseStore<StoreContext extends BaseStoreContext = BaseStoreContext> {
  protected ctx: StoreContext;

  constructor(ctx: StoreContext) {
    this.ctx = ctx;
  }

  get db() {
    return this.ctx.db;
  }

  get repositories() {
    return this.ctx.repositories;
  }

  get isOnline() {
    return isOnline();
  }

  /**
   * Получает актуальные данные из кэша или кэширует новые
   * данные в случае их просрочки или отсутствия
   * 
   * @param partsKey состовные части ключа (уникальное значение для формирования идентификатора данных)
   * @param getActialData функция для получения данных не из кэша
   * @param relevantTime срок релевантности кэша (в зависимости от скорости интернета можно менять значение)
   * @param offlineAndNotCacheHandler обработчик вызываемый в режиме оффлайн в случае отсутствия кэша
   * @returns 
   */
  protected cacheQuery<T = any>(
    partsKey: any[],
    getActialData: () => Promise<T>,
    relevantTime: number = 0,
    offlineAndNotCacheHandler?: () => Promise<T|undefined>
  ) {
    return cacheQuery(this.db, partsKey, getActialData, relevantTime, offlineAndNotCacheHandler);
  }

  /**
   * Получает актуальные данные из кэша или кэширует новые
   * данные в случае их просрочки или отсутствия, блокируя
   * одновременные операции по получению данных.
   * 
   * @param partsKey состовные части ключа (уникальное значение для формирования идентификатора данных)
   * @param getActialData функция для получения данных не из кэша
   * @param relevantTime срок релевантности кэша (в зависимости от скорости интернета можно менять значение)
   * @param offlineAndNotCacheHandler обработчик вызываемый в режиме оффлайн в случае отсутствия кэша
   * @returns 
   */
  protected cacheQueryWithBloking<T = any>(
    partsKey: any[],
    getActialData: () => Promise<T>,
    relevantTime: number = 0,
    offlineAndNotCacheHandler?: () => Promise<T|undefined>
  ) {
    return cacheQueryWithBloking(this.db, partsKey, getActialData, relevantTime, offlineAndNotCacheHandler);
  }

  removeCacheQuery(partsKey: any[]): void {
    const key = this.db.generateCacheKey(...partsKey);
    this.db.cacheQuery.delete(key);
  }

  protected cloneData<T>(data: T|Ref<T>|ComputedRef<T>): T {
    return cloneDeep(this.toRawData(data));
  }

  protected toRawData<T>(data: T|Ref<T>|ComputedRef<T>): T {
    if (isComputed(data)) {
      return toRaw(data.value);
    }

    if (isRef(data)) {
      return toRaw(data.value);
    }

    return toRaw(data);
  }
}