
import { defineComponent, onMounted, ref } from 'vue';
import { modalController } from '@ionic/vue';
import { useAlert } from '@/composables/alert';
import { useDoerMood } from '@/composables/doer';
import { useStore } from '@/composables/useApp';
import { useNavManager } from '@/composables/useNavManager';
import MoodChoice from '@/components/doer/MoodChoice.vue';
import { computed } from '@vue/reactivity';
import { useMetrica } from '@/composables/useMetrica';
import config from '@/config';

export default defineComponent({
  emits: ['logout'],
  components: {
    MoodChoice,
  },

  setup(props, { emit }) {
    const store = useStore();
    const { createAlertConfirm } = useAlert();
    const navManager = useNavManager();
    const { emitEvent } = useMetrica();

    /** @private */
    async function logout() {
      try {
        await store.user.logout();
      } finally {
        navManager.navigate({
          routerDirection: 'root',
          routerLink: { name: 'login' },
        });

        emit('logout');
        modalController.dismiss();
      }
    }

    const confirmLogout = createAlertConfirm({
      header: 'Подтверждение',
      message: 'Вы действительно хотите выйти',
      confirmBtnText: 'Выйти',

      async confirm() {
        emitEvent('user/logout');
        await logout();
      }
    });

    //#region Mood
    const doerMood = ref(0);

    const {
      submitMood,
      loadingMood,
      loadingUpdateMood,
      loadMood,
      hasMood,
      isSubmitMood,
      formSubmittedFlag,
      mood,
      isStartHasMood,
      moodExtendInfo,
      isForsedMood,
    } = useDoerMood();

    onMounted(async () => {
      await loadMood();
      doerMood.value = mood.value;
    });
    
    const submitMoodBtnDisabled = computed(() => {
      return !isSubmitMood.value
        || loadingUpdateMood.value
        || loadingMood.value
        || !doerMood.value
        // || formSubmittedFlag.value // Кнопка "Принято!" - должна работать, т.к. для нее есть специальная логика
      ;
    });

    const submitMoodBtnText = computed(() => {
      if (loadingUpdateMood.value) {
        return 'Отправляю...';
      }

      if (formSubmittedFlag.value) {
        return 'Принято!';
      }

      return 'Поставить оценку';
    });

    const titleMood = computed(() => {
      if (formSubmittedFlag.value) {
        return 'Спасибо за вашу оценку';
      }

      // NOTE: Последняя оценка mood всегда возвращается,
      // всегда нужно отображать прошлую оценку.
      // if (isStartHasMood.value) {
      //   return 'Вы уже поставили оценку';
      // }
      // if (!isSubmitMood.value) {
      //   return 'День еще не закончился';
      // }

      if (!isSubmitMood.value) {
        return 'Вы уже поставили оценку';
      }

      return 'Оцените работу вашего предприятия';
    });

    function submitForm() {
      if (formSubmittedFlag.value) {
        return modalController.dismiss();
      }

      submitMood(doerMood.value);
      emitEvent('user/mood/submit', { value: doerMood.value });
    }
    //#endregion

    function dismiss() {
      if (!isForsedMood.value) {
        modalController.dismiss();
      }
    }

    return {
      dismiss,
      confirmLogout,
      doerMood,
      submitForm,
      loadingMood,
      loadingUpdateMood,
      submitMoodBtnDisabled,
      submitMoodBtnText,
      titleMood,
      formSubmittedFlag,
      moodExtendInfo,
      isStartHasMood,
      hasMood,
      isSubmitMood,
      isForsedMood,
      contractOfferLink: config.privacyPolicyLink,
    };
  }
});
