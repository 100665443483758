import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, createBlock as _createBlock, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "c-spoiler__head-arrow-wrap" }
const _hoisted_2 = {
  key: 0,
  class: "c-spoiler__content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_collapse_transition = _resolveComponent("collapse-transition")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
    'c-spoiler': true,
    'c-spoiler--expanded': _ctx.expanded,
  })
  }, [
    _renderSlot(_ctx.$slots, "head", _normalizeProps(_guardReactiveProps({
      expanded: _ctx.expanded,
      toggle: _ctx.toggle,
      setExpand: _ctx.setExpand,
      toExpand: _ctx.toExpand,
      squeeze: _ctx.squeeze,
    })), () => [
      _createElementVNode("div", {
        class: "c-spoiler__head",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_ion_icon, { name: "core-collapse-arrow" })
        ]),
        _createTextVNode(" " + _toDisplayString(_ctx.title), 1)
      ])
    ]),
    _createVNode(_component_collapse_transition, null, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(_KeepAlive, null, [
          (_ctx.expanded)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({
            expanded: _ctx.expanded,
            toggle: _ctx.toggle,
            setExpand: _ctx.setExpand,
            toExpand: _ctx.toExpand,
            squeeze: _ctx.squeeze,
          })))
              ]))
            : _createCommentVNode("", true)
        ], 1024))
      ]),
      _: 3
    })
  ], 2))
}