
import { defineComponent, computed, onMounted } from 'vue';
import { useDoerMood } from '@/composables/doer';
import smileOk from '@/icons/doer-smiles/smile-ok.svg';
import { useRouter } from 'vue-router';

export default defineComponent({
  props: {
    title: {
      type: String,
    },
    doerId: {
      type: [String, Number],
    }
  },
  emits: ['open-emoji'],

  setup() {
    const { moodExtendInfo, loadMood } = useDoerMood();
    const router = useRouter();

    const moodIcon = computed(() => {
      return moodExtendInfo.value?.iconSrc || smileOk;
    });

    onMounted(loadMood);

    let toDevPageCounter = 0;
    function toDevPage() {
      toDevPageCounter++;

      if (toDevPageCounter >= 10) {
        router.push({ name: 'dev' });
      }
    }

    return {
      moodIcon,
      toDevPage,
    };
  }
});
