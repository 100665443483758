import { BaseStore, BaseStoreContext } from './BaseStore';
import { Ref, ref, ComputedRef, computed } from 'vue';

export const TIMER_UPDATE_INTERVAL_MS = 10_000;

// NOTE: Глобальный таймер, чтобы предотвратить активацию
// нескольких таймеров, при переключении хранилища
let timerInterval = 0;

export class TimerStore extends BaseStore {
  private currentTime: Ref<Date>;
  readonly time: ComputedRef<Date>;

  constructor(ctx: BaseStoreContext) {
    super(ctx);

    this.currentTime = ref(new Date());
    this.time = computed(() => this.currentTime.value);
  }

  activate() {
    if (timerInterval) {
      this.deactivate();
    }

    timerInterval = setInterval(() => {
      this.currentTime.value = new Date();
    }, TIMER_UPDATE_INTERVAL_MS);
  }

  deactivate() {
    if (timerInterval) {
      clearInterval(timerInterval);
      timerInterval = 0;
    }
  }
}