
import { defineComponent } from 'vue';

export default defineComponent({
  components: {},
  props: {
    loadingText: {
      type: String,
    },
  },
});
