
import { defineComponent, inject, ComputedRef, PropType, computed, ref, watch } from 'vue'

export default defineComponent({
  props: {
    name: {
      type: String as PropType<string>,
      default: 'default',
    }
  },
  setup(props) {
    const selectedTab = inject('seletedTab') as ComputedRef<string|undefined>;
    const isActive = computed(() =>
      selectedTab.value === props.name
      || (!selectedTab.value && props.name === 'default')
    );

    // Внутренние компоненты будут создаваться только в момент первого отображения,
    // в последующем они будут скрываться/показываться
    const isCreated = ref(isActive.value);
    if (false === isCreated.value) {
      const stopWatchCreated = watch(isActive, active => {
        if (active) {
          isCreated.value = true;
          stopWatchCreated();
        }
      });
    }

    return {
      selectedTab,
      isActive,
      isCreated,
    }
  }
});
