import { isClientError } from "@/repositories";
import { nl2br } from "@/utils/string"
import { isString, get, has } from "lodash"

export const UNKNOW_ERROR_MESAGE = 'Возникла неизвестная ошибка,<br> обратитесь к администратору';

/**
 * Преобразует значение в форматированный JSON (с отступами и переносами)
 * 
 * @param value значение для конвертирования
 * @param space кол-во пробелов или сами пробелы в виде строки
 */
export function stringToFormatJSON(value: any, space: number|string = 2): string {
  return JSON.stringify(value, null, space);
}

/**
 * Преобразует информацию о возникшей ошибки в текст c HTML разметкой
 * @param message сообщение об ошибке
 * @param newLineTag тег который будет использован в качестве переноса
 */
export function errorToMessageString(message: any, newLineTag = '<br>'): string {
  if (typeof message === 'string') {
    return nl2br(message.trim(), newLineTag);
  }

  if (message instanceof Error) {
    if (isClientError(message)) {
      if (has(message.response, 'data.error')) {
        return get(message.response, 'data.error');
      }
    }

    return message.message;
  }

  if (Array.isArray(message)) {
    const listItems = message
      .map(item => `<li>${errorToMessageString(item)}</li>`)
      .join('\n');
    
    return `<ul>${listItems}</ul>`;
  }

  if (typeof message === 'object' && isString(get(message, 'message', null))) {
    return message.message;
  }

  return `<pre>${stringToFormatJSON(message)}</pre>`;
}
