
import { RouteLocationRaw, useRoute } from 'vue-router';
import { defineComponent, computed } from 'vue';
import { useNavManager } from '@/composables/useNavManager';
import { useMetrica } from '@/composables/useMetrica';
import { get } from 'lodash';
import { useI18n } from 'vue-i18n';
import checkList from '@/icons/check-list.svg';

export interface NavigationItem {
  to: RouteLocationRaw;
  icon: string;
  text: string;
}

export type NavigationCollection = Record<string, NavigationItem>;

export default defineComponent({
  setup() {
    const route = useRoute();
    const currentTab = route.meta.tab as string|undefined;
    const navManager = useNavManager();
    const { emitEvent } = useMetrica();
    const { t } = useI18n();

    const navItems = computed<NavigationCollection>(() => ({
      visits: {
        to: { name: 'visits' },
        icon: checkList,
        text: t('components.app_footer.menu_item_visits'),
      },
      pricelist: {
        to: { name: 'pricelist' },
        icon: 'core-price',
        text: t('components.app_footer.menu_item_pricelist'),
      },
      market: {
        to: { name: 'market' },
        icon: 'core-bag',
        text: t('components.app_footer.menu_item_market'),
      },
      pos: {
        to: { name: 'pos' },
        icon: 'core-cash',
        text: t('components.app_footer.menu_item_pos'),
      },
      reports: {
        to: { name: 'reports' },
        icon: 'core-statistic',
        text: t('components.app_footer.menu_item_reports'),
      },
    }));

    function toNavigate(to: RouteLocationRaw) {
      const routeName = get(to, 'name', 'unknown')

      emitEvent(`navigation/footer/${routeName}`);

      navManager.navigate({
        routerLink: to,
        routerDirection: 'root',
      });
    }

    return {
      navItems,
      currentTab,
      toNavigate,
    };
  },
});
