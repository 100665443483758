
import { ComponentPublicInstance, defineComponent, onMounted, ref, watch } from 'vue';
import { modalController } from '@ionic/vue';
import { RangeTabData, useDoerProvidedServicesStat, useDoersStatRangeTabs } from '@/composables/doer';
import AppHeaderForDoer from '@/components/layout/AppHeaderForDoer.vue';
import ProvideServicesTotalCard from '@/components/doer/ProvideServicesTotalCard.vue';
import DoerEmojiReportAndNav from '@/views/DoerEmojiReportAndNav.vue';
import VisitGroupCard from '@/components/doer/VisitGroupCard.vue';
import ChoiceBeetweenDateModal from './ChoiceBeetweenDateModal.vue';
import { computed } from '@vue/reactivity';
import { useUser } from '@/composables/useUser';
import { useStore } from '@/composables/useApp';
import { useMetrica } from '@/composables/useMetrica';
import { isClientError } from '@/repositories';
import moment from 'moment';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    AppHeaderForDoer,
    ProvideServicesTotalCard,
    VisitGroupCard,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { emitEvent } = useMetrica();

    const startTime = ref<Date>(new Date());
    const endTime = ref<Date>(new Date());
    const selectedDateFormatted = computed(() => {
      const st = moment(startTime.value);
      const et = moment(endTime.value);
      if (st.isSame(et, 'date')) {
        return st.format('DD MMMM');
      }

      return st.format('DD MMMM') + ' - ' + et.format('DD MMMM');
    });

    const {
      rangeDateTabs,
      current: currentTab,
      rangeDateTabsKeyByName
    } = useDoersStatRangeTabs({
      model: { startTime, endTime },
      choiceCustom: openChoiceBeetweenDateModal,
    });

    rangeDateTabsKeyByName.value['today'].setCurrent();

    const {
      providedServices,
      total: totalDoerCost,
      loadStat,
      provideServicesGroupByCar,
    } = useDoerProvidedServicesStat({
      startTime,
      endTime,
    });

    const loadingContentRef = ref<ComponentPublicInstance<any>|null>(null);
    watch([startTime, endTime], () => {
       loadingContentRef.value?.load();
    });

    const tabsShow = ref(true);
    function onScroll(ev: CustomEvent) {
      if (ev.detail.deltaY < -8 || ev.detail.scrollTop < 150) {
        tabsShow.value = true;
      } else if (ev.detail.deltaY > 10) {
        tabsShow.value = false;
      }
    }

    async function openEmojiAndNav() {
      const modal = await modalController.create({
        component: DoerEmojiReportAndNav as any,
        cssClass: 'doer-emoji-modal-view'
      });

      modal.present();

      emitEvent('home/mood-or-nav/open');
    }

    onMounted(() => {
      // Показываем мойщику, что ему нужно оценить работу.
      watch(store.doer.isForsedMood, forsed => {
        if (forsed) {
          openEmojiAndNav();
        }
      }, { immediate: true });
    });

    const { fullName: userFullname, user } = useUser();
    const userId = computed(() => user.value?.userId || 0);

    function setTab(tab: RangeTabData) {
      tab.setCurrent();
      emitEvent('home/tab/change', {
        label: tab.label,
        name: tab.name,
      });
    }

    function responseError(e: any) {
      if (isClientError(e) && e.response?.status == 401) {
        store.user.removeAuthToken();
        router.replace({ name: 'login' });
      }
    }

    async function openChoiceBeetweenDateModal() {
      const modal = await modalController.create({
        cssClass: 'modal-fullscreen',
        component: ChoiceBeetweenDateModal as any,
        componentProps: {
          value: [startTime.value, endTime.value],
          
          setBeetweenDate(between: { begin: Date; end: Date; }) {
            startTime.value = between.begin;
            endTime.value = between.end;
          }
        }
      });

      modal.present(); // async 

      return modal;
    }

    return {
      providedServices,
      totalDoerCost,
      rangeDateTabs,
      selectedDateFormatted,
      loadStat,
      currentTab,
      onScroll,
      tabsShow,
      loadingContentRef,
      openEmojiAndNav,
      userFullname,
      userId,
      setTab,
      provideServicesGroupByCar,
      responseError,
    };
  }
});
