<template>
  <ion-button class="c-transparent-button">
    <slot />
  </ion-button>
</template>

<style lang="scss">
.c-transparent-button {
  --background: transparent;
  --color: inherit;
  --background-activated: var(--ion-color-dark, transparent);
  --background-activated-opacity: 0.6;
  --background-hover: rgba(150, 150, 150, 0.1);
}
</style>