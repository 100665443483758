
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    selected: {
      type: Boolean as PropType<boolean>,
      default: false,
    }
  }
});
