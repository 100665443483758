import config from '@/config'

export const BASE_URL_PATH = /^https?:\/\/[A-z\d.]{1,}(\.[A-z\d]+){1}(:[\d]{1,6})?(\/[A-z\d-_.]*)*$/;

/**
 * Очистит ссылку и оставит только домен с портом
 * 
 * in: https://Ci828.Core12.ru:3333/path/1/2
 * out: ci828.core12.ru:3333
 * 
 * @param domain 
 * @returns 
 */
export function prepareDomain(domain: string) {
  return domain
    .toLowerCase()
    .replace(/^https?:\/\//, '')
    .replace(/([a-z\d.:]+)\/(.*)/g, '$1');
}

/**
 * Проверяет на корректность базовый url
 * 
 * @param domain 
 * @returns 
 */
export function testValidBaseUrl(domain: string): boolean {
  return BASE_URL_PATH.test(domain);
}

/**
 * Обработает базовый url
 * 
 * @param baseUrl 
 * @returns 
 */
export function prepareBaseUrl(baseUrl: string) {
  let endApiPathRegv = new RegExp(`${config.apiPath}$`);
  return baseUrl
    .replace(/\/$/, '')
    .replace(endApiPathRegv, '');
}