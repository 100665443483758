
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    title: String as PropType<string>,
    defaultTitle: String as PropType<string>,

    hideBackButton: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },

  data() {
    const queryTitle = this.$route.query.title as string|undefined;
    return {
      queryTitle,
    };
  },

  computed: {
    finalTitle(): string|undefined {
      return this.title || this.queryTitle || this.defaultTitle;
    }
  }
});
