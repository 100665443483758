import config from '@/config';
import { AppDB } from '@/database/AppDB';
import { createClient, createRepositories, Repositories } from '@/repositories';
import { createStore, StoreInstance } from '@/store';
import { prepareDomain, prepareBaseUrl } from '@/utils/domain';
import { App, inject } from '@vue/runtime-core';

export const LS_KEY_BASE_DOMAIN = 'app/baseDomain';

export interface PreloadOptions {
  progressCallback?(percent: number, message: string): void;
}

export function createAppContext() {
  const apiDomain = getApiDomain();
  const httpClient = createClient(`${apiDomain}${config.apiPath}`);
  const repositories = createRepositories(httpClient);

  let dbInstance = new AppDB(prepareDomain(apiDomain));
  const store = createStore(dbInstance, repositories, httpClient);

  function setApiDomain(domain: string) {
    // TODO: Возможно перед применением домена
    // нужно протестировать каконибудь запрос

    localStorage.setItem(LS_KEY_BASE_DOMAIN, prepareBaseUrl(domain));
    httpClient.defaults.baseURL = `${getApiDomain()}${config.apiPath}`;

    dbInstance = new AppDB(domain);
    store.recreateInstances(dbInstance);
  }

  function getApiDomain() {
    return localStorage.getItem(LS_KEY_BASE_DOMAIN) || config.defaultBaseDomain;
  }

  async function clearCache() {
    dbInstance.cacheQuery.clear();
    dbInstance.keyValue.clear();

    // Пересоздать все хранилища, чтобы кэш из памяти тоже был удален
    store.recreateInstances(dbInstance);
  }

  return {
    http: httpClient,
    repositories,
    setApiDomain,
    getApiDomain,
    clearCache,
    get store() { return store; },
    getDb() { return dbInstance; },

    destroy() {
      throw new Error('AppContext.destroy() не реализована!');
    },
  };
}

export type AppContext = ReturnType<typeof createAppContext>;

export interface AppContextConfig {
  context?: AppContext;
  global?: boolean;
  injectKey?: string;
}

export default {
  install(app: App, config: AppContextConfig = {}) {
    const appContext = config.context || createAppContext();

    if (config.global !== false) {
      app.config.globalProperties.$app = appContext;
      Object.defineProperty(app.config.globalProperties, '$store', {
        get() { return appContext.store; }
      });
    }
    
    app.provide(config.injectKey || 'appContext', appContext);
  }
};

export function useApp(key?: string): AppContext {
  return inject(key || 'appContext') as AppContext;
}

export function useStore(appContextKey?: string): StoreInstance {
  const appContext = inject(appContextKey || 'appContext') as AppContext;
  return appContext.store;
}

export function useRepositories(appContextKey?: string): Repositories {
  const appContext = inject(appContextKey || 'appContext') as AppContext;
  return appContext.repositories;
}