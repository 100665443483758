import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "с-error-block__message-header" }
const _hoisted_2 = {
  key: 0,
  class: "с-error-block__message-title"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "с-error-block__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
    'с-error-block': true,
    'с-error-block--valign': _ctx.valign,
  })
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["с-error-block__message", { 'с-error-block__message--string': _ctx.isMessageTypeString }])
    }, [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({ strinfifyMessage: _ctx.strinfifyMessage, textTitle: _ctx.textTitle, isMessageTypeString: _ctx.isMessageTypeString })), () => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_ion_icon, { src: _ctx.errorMessageIcon }, null, 8, ["src"]),
          (_ctx.textTitle)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.textTitle), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: "с-error-block__message-html",
          innerHTML: _ctx.strinfifyMessage
        }, null, 8, _hoisted_3)
      ])
    ], 2),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "actions.before"),
      _renderSlot(_ctx.$slots, "actions", _normalizeProps(_guardReactiveProps({ strinfifyMessage: _ctx.strinfifyMessage, btnText: _ctx.btnText })), () => [
        _createVNode(_component_ion_button, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('reload'))),
          color: "primary",
          expand: "block",
          shape: "round"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.btnText || _ctx.$t('components.error_block.btn_reload')), 1)
          ]),
          _: 1
        })
      ]),
      _renderSlot(_ctx.$slots, "actions.after")
    ])
  ], 2))
}