import SimpleKeyboard from 'simple-keyboard';
import { KeyboardOptions } from 'simple-keyboard/build/interfaces';
import { computed, ref, Ref, watch, onBeforeUnmount } from 'vue';

export function useVirtualKeyboard(containerRef: Ref<HTMLDivElement|null>, options: KeyboardOptions) {
  const inputFocus = ref(null) as Ref<HTMLInputElement|HTMLTextAreaElement|null>;
  const ctx = {
    instance: null as SimpleKeyboard|null,
    inputFocus,
    onFocus,
    onInput,
    onBlur,
    setInput,
    setCurrentInputValue,
    isInputSelected: computed(() => !!inputFocus.value),
  };

  onBeforeUnmount(() => {
    ctx.instance?.destroy();
  });

  watch(containerRef, container => {
    ctx.instance?.destroy();
    ctx.instance = new SimpleKeyboard(container as HTMLDivElement, {
      theme: "hg-theme-core12",
      ...options,
      onChange: keyboardOnChange,
      preventMouseDownDefault: true,
      preventMouseUpDefault: true,
      useMouseEvents: true, // fix: иначе при прокрутке на touch устройстве нажимаются кнопки
    });
  });

  function setInput(el: HTMLInputElement|HTMLTextAreaElement|null) {
    ctx.inputFocus.value = el;

    if (ctx.instance && ctx.inputFocus.value) {
      const inputName = ctx.inputFocus.value.name || 'default';
      ctx.instance.setOptions({ inputName });
      ctx.instance.setInput(ctx.inputFocus.value.value, inputName);
    }
  }

  function setCurrentInputValue(value: string) {
    if (ctx.instance && ctx.inputFocus.value) {
      ctx.instance.setInput(value);
      ctx.inputFocus.value.value = value;

      // fix: Для правильной работы Vue v-model
      ctx.inputFocus.value.dispatchEvent(new KeyboardInput('input', {
        composed: true,
      }));
    }
  }

  function onFocus(event: FocusEvent): void {
    setInput(event.target as HTMLInputElement|HTMLTextAreaElement);
  }

  function onInput(event: Event|InputEvent): void {
    if (event instanceof KeyboardInput) return; // Был сгенерирован keyboardOnChange

    ctx.inputFocus.value = event.target as HTMLInputElement|HTMLTextAreaElement;
    ctx.instance?.setInput(ctx.inputFocus.value.value, ctx.inputFocus.value.name);
  }

  function onBlur(/* event: CustomEvent */) {
    ctx.inputFocus.value = null;
  }

  function keyboardOnChange(newValue: string): void {
    if (ctx.inputFocus.value) {
      ctx.inputFocus.value.value = newValue;

      // fix: Для правильной работы Vue v-model
      ctx.inputFocus.value.dispatchEvent(new KeyboardInput('input', {
        composed: true,
      }));

      // fix: Недоработка библиотеки, при печати через
      // виртуальную клавиатуру постоянно сбрасывается указатель
      const caretStart = ctx.instance?.getCaretPosition();
      const caretEnd = ctx.instance?.getCaretPositionEnd();
      if (typeof caretStart === 'number') ctx.inputFocus.value.selectionStart = caretStart;
      if (typeof caretEnd === 'number') ctx.inputFocus.value.selectionEnd = caretEnd;
    }
  }

  return ctx;
}

export class KeyboardInput extends InputEvent {}

export type VirtualKeyboardContext = ReturnType<typeof useVirtualKeyboard>;