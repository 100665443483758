
import { defineComponent } from 'vue';
import { popoverController } from '@ionic/vue';
import AppSettings from '@/components/app-service/AppSettings.vue';
import PopoverLayout from '@/components/layout/PopoverLayout.vue';

export default defineComponent({
  components: {
    AppSettings,
    PopoverLayout,
  },

  setup() {
    function close() {
      popoverController.dismiss();
    }

    return {
      close,
    };
  }
});
