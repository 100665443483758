import { ClientInstance } from './Clients/AxiosClient';
export * from './Clients/AxiosClient';

import { UserRepository } from './UserRepository';
import { DoerRepository } from './DoerRepository';

export type { ClientInstance };

export type Repositories = ReturnType<typeof createRepositories>;

export function createRepositories(client: ClientInstance) {
  return {
    user: new UserRepository(client),
    doer: new DoerRepository(client),
  };
}

export {
  UserRepository,
}
