
import { defineComponent } from 'vue';
import { useUser } from '@/composables/useUser';
import { useMetrica } from '@/composables/useMetrica';
import { useNavManager } from '@/composables/useNavManager';
import { useStore } from '@/composables/useApp';
import { useAlert } from '@/composables/alert';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  emits: ['logout'],

  setup(props, { emit }) {
    const store = useStore();
    const { fullName } = useUser();
    const navManager = useNavManager();
    const { emitEvent } = useMetrica();
    const { createAlertConfirm } = useAlert();
    const { t } = useI18n();

    /** @private */
    async function logout() {
      try {
        await store.user.logout();
      } finally {
        navManager.navigate({
          routerDirection: 'root',
          routerLink: { name: 'login' },
        });

        emit('logout');
      }
    }

    const confirmLogout = createAlertConfirm({
      header: t('components.user_settings.alert_exit_header'),
      message: t('components.user_settings.alert_exit_message'),
      confirmBtnText: t('components.user_settings.alert_exit_btn_confirm'),
      async confirm() {
        emitEvent('user/logout');
        await logout();
      }
    });

    return {
      fullName,
      confirmLogout,
    };
  }
});
