
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    tabletAdaptive: {
      type: Boolean,
      default: true,
    },
  }
});
