
import { defineComponent, ref, watch } from 'vue';
import { MOOD_DEFAULT } from '@/composables/doer';

export default defineComponent({
  emits: ['update:modelValue'],

  props: {
    modelValue: {
      type: [Number, String],
    },
    lock: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit }) {
    const internalValue = ref(Number(props.modelValue));
    watch(() => props.modelValue, v => internalValue.value = Number(v || 0));

    function setValue(value: number) {
      if (props.lock) return;

      internalValue.value = value;
      emit('update:modelValue', value);
    }

    return {
      moods: MOOD_DEFAULT,
      setValue,
      internalValue,
    };
  },
});
