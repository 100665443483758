
import { defineComponent, ref, watch } from 'vue'
import CollapseTransition from './CollapseTransition.vue'

export default defineComponent({
  components: {
    CollapseTransition,
  },
  props: {
    expand: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'Развернуть область'
    }
  },
  setup(props, { emit }) {
    const expanded = ref(props.expand);
    watch(() => props.expand, v => expanded.value = v);

    function setExpand(val: boolean) {
      expanded.value = val;
      emit('update:show', val);
    }

    const toggle = () => setExpand(!expanded.value);
    const toExpand = () => setExpand(true);
    const squeeze = () => setExpand(false);

    return {
      expanded,
      toggle,
      setExpand,
      toExpand,
      squeeze,
    };
  }
});
