import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-image-bg__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "c-image-bg",
    style: _normalizeStyle({
    backgroundImage: _ctx.src ? `url(${_ctx.src})` : null,
    paddingTop: _ctx.paddingY,
    paddingBottom: _ctx.paddingY,
  })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 4))
}