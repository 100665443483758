
import { defineComponent } from 'vue'
import AppSettings from '@/components/app-service/AppSettings.vue'
import { modalController } from '@ionic/vue'

export default defineComponent({
  components: {
    AppSettings,
  },
  setup() {
    function close() {
      return modalController.dismiss();
    }

    return {
      close,
    };
  },
});
