import { isPlatform } from "@ionic/core";

export function iOSVersion(): [number, number, number] {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    let v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (v) { 
      return [
        parseInt(v[1], 10),
        parseInt(v[2], 10),
        parseInt(v[3] || '0', 10)
      ];
    }
  }

  return [0, 0, 0];
}

export function addBodyLegacyClasses() {
  if (false === isPlatform('ios')) return;

  const iosVersion = iOSVersion();
  if (iosVersion[0] && iosVersion[0] <= 13) {
    document.body.classList.add('ios-13d');
  }
}