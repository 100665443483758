<template>
  <div class="c-tablet-side-layout">
    <div class="c-tablet-side-layout__side">
      <div class="c-tablet-side-layout__side-inner-content">
        <div class="c-tablet-side-layout__inner-scroll">
          <slot name="side" />
        </div>
      </div>

      <div class="c-tablet-side-layout__side-inner-after" v-if="$slots['side-after']">
        <slot name="side-after" />
      </div>
    </div>

    <div class="c-tablet-side-layout__content">
      <div class="c-tablet-side-layout__inner-scroll">
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/scss/abstract";

.c-tablet-side-layout {
  @include ionic-tablet {
    display: flex;
    min-height: 100%;
    align-items: stretch;

    &__side {
      width: 36%;
      max-width: 400px;
      min-width: 280px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      border-right: 1px solid var(--core-divider-color);
    }

    &__content {
      flex: 1 1 0%;
      position: relative;
    }

    &__inner-scroll {
      @include parent-full-size;
      position: absolute;
      z-index: 0;
      overflow-y: auto;
    }

    &__side-inner-content {
      flex: 1 1 0%;
      position: relative;
    }
  }
}
</style>