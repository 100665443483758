
import { defineComponent } from 'vue';
import LoginForm from '@/components/user/LoginForm.vue';
import { phonePrepare } from '@/utils/string';
import { useSettingsApp } from '@/composables/useSettingsApp';
import { useNavManager } from '@/composables/useNavManager';
import { useStore } from '@/composables/useApp';
import { useMetrica } from '@/composables/useMetrica';
import config from '@/config';

const PHONE_DEFAULT = '+7 (499) 346-09-00';

export default defineComponent({
  components: {
    LoginForm,
  },

  setup() {
    const store = useStore();
    const navManager = useNavManager();
    const { openSettings } = useSettingsApp();
    const { emitEvent } = useMetrica();

    if (store.user.isAuth) {
      navManager.navigate({
        routerDirection: 'root',
        routerLink: {
          name: 'doer_provided_services',
        }
      });
    }

    const supportPhone = { // TODO: Брать номер из конфига [config]
      text: PHONE_DEFAULT,
      link: 'tel:' + phonePrepare(PHONE_DEFAULT, '+74993460900'),
    };

    function toHome() {
      navManager.navigate({
        routerDirection: 'root',
        routerLink: { name: 'doer_provided_services' },
      });
    }

    function loginned() {
      emitEvent('user/login');
      toHome();
    }

    return {
      loginned,
      supportPhone,
      openSettings,
      isAuth: store.user.isAuth,
      contractOfferLink: config.privacyPolicyLink,
    };
  },
});
