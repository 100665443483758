
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    ratio: {
      type: Number as PropType<number>,
      default: 1,
    },
    src: {
      type: String as PropType<string>,
      required: false,
    }
  },
  setup(props) {
    const paddingY = computed(() => {
      return Math.abs(Math.round(props.ratio * 100 / 2)) + '%';
    });

    return {
      paddingY,
    };
  }
});
