export default {

  /** Ключ от App Метрики (яндекс) */
  appMetricaApiKey: '',

  /** Идентификатор Яндекс.Метрики */
  yandexMetricaId: 88820396,

/** Данные для инициализации firebase application */
  firebaseConfig: {
    apiKey: "AIzaSyAODo_rRUA9dYVr07pPQyKxEsvxdklSWQQ",
    authDomain: "core12-doer.firebaseapp.com",
    projectId: "core12-doer",
    storageBucket: "core12-doer.appspot.com",
    messagingSenderId: "432842027689",
    appId: "1:432842027689:web:c1fcbf1c338d4df217efb8"
  },

  firebaseVapidKey: 'BIHEYlBrlZtmSywiecMZrkCLhCKj5RWerDwH3x0wME8YoM-bR8ZZcQrO7q6m24Rf6CWD-N22RB7vFcCA4FUhhrE'

}
