import { computed, ref } from 'vue'
import { Network } from '@ionic-native/network'
import { isPlatform } from '@ionic/vue';

export interface NetworkInformation {
  downlink?: number;       // int32
  effectiveType?: string;  // 4g, 3g, wifi ...
  type?: string;           // 4g, 3g, wifi ...
  onchange?: any;          // function
  saveData?: boolean;
  
  /**
   * Расчетное эффективное время приема-передачи текущего соединения,
   * округленное до ближайшего числа, кратного 25 миллисекундам
   */
  rtt?: number; // int32 | быстрый +-100 | средний +-800 | медленный 1400+
}

export function getConnetion(): NetworkInformation|null {
  const navigator: any = window.navigator || {};
  return navigator.connection
    || navigator.mozConnection
    || navigator.webkitConnection
    || null;
}

/**
 * Наличие подключения к сети-интернет
 * @returns 
 */
export function isOnline(): boolean {
  // TODO: Прикрутить кордову

  return (window?.navigator?.onLine === false) ? false : true;
}

/**
 * Отсутствие подключения к сети-интернет
 * @returns 
 */
export function isOffline(): boolean {
  return !isOnline();
}

/**
 * Медленное подключение к интернету
 * @returns 
 */
export function isSlowNetwork(): boolean {
  const connection = getConnetion();
  if (connection) {
    if (connection.rtt && connection.rtt > 500) return true;
  } else {
    return (Network.type === Network.Connection.CELL_2G);
  }

  return false;
}

/**
 * Добавить обработчик для события подключения к интернету
 * 
 * @param listener 
 */
export function bindOnline(listener: () => void|Promise<void>) {
  if (isPlatform('capacitor')) {
    Network.onConnect().subscribe(() => { listener(); });
  } else {
    window.addEventListener('online', listener);
  }
}

/**
 * Добавить обработчик для события отключения от интеренета
 * 
 * @param listener 
 */
export function bindOffline(listener: () => void|Promise<void>) {
  if (isPlatform('capacitor')) {
    Network.onDisconnect().subscribe(() => { listener(); });
  } else {
    window.addEventListener('offline', listener);
  }
}

//#region Состояние индикатора подключения к сети
export enum NetworkIndicator {
  Offline = 'offline',
  Slow = 'slow',
  Average = 'average',
  Quick = 'quick',
  Unknown = 'unknown',
}

export const stateRRT = ref(0);
export const stateNetworkIndicator = computed(() => {
  if (stateRRT.value === 0) return NetworkIndicator.Unknown;
  if (stateRRT.value < 0)   return NetworkIndicator.Offline;
  if (stateRRT.value < 101) return NetworkIndicator.Quick;
  if (stateRRT.value < 801) return NetworkIndicator.Average;
  
  return NetworkIndicator.Slow;
});

const INTERVAL_RRT_MS = 3000;
let intervalRRTId: number|null = null;
export function startIntervalRRT() {
  if (intervalRRTId) return;

  intervalRRTId = setInterval(() => {
    if (isOffline()) {
      stateRRT.value = -1;
      return;
    }

    const connection = getConnetion();
    if (connection && connection.rtt) {
      stateRRT.value = connection.rtt;
    } else {
      stateRRT.value = getConnectionSpeedCrutch();
    }
    
  }, INTERVAL_RRT_MS);
}

export function getConnectionSpeedCrutch() {
  switch (Network.type) {
    case Network.Connection.CELL_2G: return 1000;
    case Network.Connection.CELL_3G: return 700;
    case Network.Connection.CELL_4G: return 100;
    case Network.Connection.WIFI: return 20;
    case Network.Connection.ETHERNET: return 20;
    case Network.Connection.NONE: return -1;
  }

  return 0;
}

export function stopIntervalRRT() {
  if (intervalRRTId) {
    clearInterval(intervalRRTId);
    intervalRRTId = null;
  }
}

export const networkStatusColor = computed<string>(() => {
  switch(stateNetworkIndicator.value) {
    case NetworkIndicator.Offline: return 'var(--ion-color-danger)';
    case NetworkIndicator.Slow: return 'var(--ion-color-primary)';
    case NetworkIndicator.Average: return 'var(--ion-color-warning)';
    case NetworkIndicator.Quick: return 'var(--ion-color-success)';
  }

  return 'var(--ion-color-step-200)';
});

export const networkStatusText = computed<string>(() => {
  switch(stateNetworkIndicator.value) {
    case NetworkIndicator.Offline: return 'Нет сети';
    case NetworkIndicator.Slow: return 'Медленная';
    case NetworkIndicator.Average: return 'Средняя';
    case NetworkIndicator.Quick: return 'Хорошая';
  }

  return 'Неизвестно';
});
//#endregion