
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: [String, Number] as PropType<string|number>,
      default: 0,
    },
    color: {
      type: String as PropType<string>,
      required: false
    }
  },

  computed: {
    progressNumber(): number {
      return Number(this.value);
    },

    progressWidth(): number {
      return Math.max(0, Math.min(1, this.progressNumber)) * 100;
    },
  }
});
