import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "cd-mood-choice__item-inner-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
    'cd-mood-choice': true,
    'cd-mood-choice--lock': _ctx.lock,
  })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.moods, (m) => {
      return (_openBlock(), _createElementBlock("div", {
        key: m.value,
        onClick: ($event: any) => (_ctx.setValue(m.value)),
        class: _normalizeClass({
        'cd-mood-choice__item': true,
        'cd-mood-choice__item--selected': (m.value == _ctx.internalValue),
      }),
        style: _normalizeStyle({ color: m.color })
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ion_icon, {
            src: m.iconSrc
          }, null, 8, ["src"])
        ])
      ], 14, _hoisted_1))
    }), 128))
  ], 2))
}