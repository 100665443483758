/**
 * «Мягко» преобразует строку в тип boolean
 * '', '0', 'n', 'no', 'null' - false, остальное true
 * 
 * @param val входная строка
 */
export function stringToSoftBoolean(val: string) {
  val = val.toLowerCase();

  switch (val) {
    case '0':
    case 'n':
    case 'no':
    case 'null':
    case 'false':
      return false;
  }

  return !!val;
}

/**
 * «Мягко» преобразует значение в тип boolean
 * 0, null, function, '', '0', 'n', 'no', 'null' - false
 * 1, 'not_empty_string', object, !!<number> - true
 * остальное - false
 * 
 * @param val значение для преобразования
 */
export function toSoftBoolean(val: any): boolean {
  switch (typeof val) {
    case 'boolean': return val;
    case 'number': return !!val;
    case 'object': return (val !== null);
    case 'string': return stringToSoftBoolean(val);
  }

  return false;
}
