
import { defineComponent, PropType, ref, watch } from 'vue';
import { modalController } from '@ionic/vue';
import CInfiniteCalendar from '@/components/core/InfiniteCalendar/CInfiniteCalendar.vue';

export default defineComponent({
  components: {
    CInfiniteCalendar,
  },
  props: {
    setBeetweenDate: {
      type: Function as PropType<(between: { begin: Date; end: Date; }) => void>,
      required: true
    },
    value: {
      type: Array as PropType<Date[]>,
      required: false
    }
  },
  setup(props) {
    const dateBetween = ref<Date[]>(props.value ? [...props.value] : []);

    watch(dateBetween, value => {
      if (value?.length === 2) {
        props.setBeetweenDate({
          begin: value[0],
          end: value[1]
        });

        // Если закрывать сразу, то закрывается слишком резко, визуально не очень приятно
        setTimeout(close, 220);
      }
    });

    function close() {
      return modalController.dismiss();
    }

    let prevYScrollTop = 0;
    const headerHide = ref(false);
    function onCalendarScroll(ev: Event, offsets: { dOffsetTop: number; scrollTop: number; }) {
      const deltaY = prevYScrollTop - offsets.scrollTop;
      // Сохраняем значение с поправкой на смещение (т.к. у нас календарь с бесконечной прокруткой)
      prevYScrollTop = offsets.scrollTop + offsets.dOffsetTop;

      if (!headerHide.value && deltaY < -3) {
        headerHide.value = true;
      } else if (headerHide.value && deltaY > 3) {
        headerHide.value = false;
      }
    }

    return {
      dateBetween,
      close,
      onCalendarScroll,
      headerHide,
    };
  },
});
