
import { usePush } from '@/composables/push';
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    const push = usePush();

    return {
      pushToken: push.getToken() || '-',
    };
  }
});
