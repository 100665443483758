// import { App as VueApp } from 'vue'
// import router from '@/router'
import { App as AppCapacitor } from '@capacitor/app'
import { alertController } from '@ionic/vue'

export async function exitAppConfirm() {
  const alert = await alertController.create({
    header: 'Подтвердите выход',
    buttons: [
      {
        text: 'Отмена',
        role: 'cancel'
      },
      {
        text: 'Выйти',
        async handler() {
          alert.dismiss(); // async
          AppCapacitor.exitApp();
        }
      }
    ]
  });

  alert.present(); // async

  return alert;
}

export const NativeHandlersPlugin = {
  install(/* app: VueApp */) {
    AppCapacitor.addListener('backButton', ev => {
      if (ev.canGoBack) {
        // Действие назад не требуется, т.к. ionic: hardwareBackButton: true,
        // уже производит действие назад
        // router.back();
        // window.history.back();
      } else {
        exitAppConfirm(); // async
      }
    });
  }
};

export default NativeHandlersPlugin;