<template>
  <div class="c-modal-content">
    <!-- fix: @touchstart.stop, что-бы при скролле не закрывалось окно от свайпа вниз -->
    <div class="c-modal-content__inner-scroll" @touchstart.stop>
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
@import '@/scss/abstract';

.c-modal-content {
  &__inner-scroll {
    max-height: calc(60vh - var(--ion-safe-area-top, 0) - var(--ion-safe-area-bottom, 0));
    touch-action: pan-y;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    -webkit-overflow-scrolling: touch;
    will-change: scroll-position;
    z-index: 0;

    @include ionic-tablet {
      max-height: calc(70vh - var(--ion-safe-area-top, 0) - var(--ion-safe-area-bottom, 0));
    }

    @media (max-device-width: 320px) {
      // fix: small iPhone (5/SE/5s) iOS < 13
      height: 50vh;
    }
  }
}
</style>