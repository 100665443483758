
import { computed, defineComponent, PropType } from 'vue';
import { useCurrency, PriceFormatOptions } from '@/composables/currency';

export default defineComponent({
  props: {
    price: null,
    options: {
      type: Object as PropType<PriceFormatOptions>,
      default: () => ({}),
    },
    emptyDisplay: {
      type: String,
      required: false,
    }
  },
  setup(props) {
    const { currencyConfig, formatCurrency } = useCurrency();

    const priceFormatted = computed(() => {
      if ((props.price === null || props.price === undefined) && props.emptyDisplay !== undefined) {
        return props.emptyDisplay;
      }

      return formatCurrency(props.price, props.options);
    });

    return {
      priceFormatted,
      currencyConfig,
    };
  }
});
