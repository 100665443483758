
import { isString, get } from 'lodash';
import { computed, defineComponent } from 'vue';
import { errorToMessageString } from '@/helpers/error';
import errorMessageIcon from '@/icons/error-message-icon.svg';

export default defineComponent({
  emits: ['reload'],

  props: {
    message: null,
    hasButton: {
      type: Boolean,
      default: false,
    },
    btnText: {
      type: String,
      required: false,
    },
    textTitle: {
      type: String,
      default: ''
    },
    valign: {
      type: Boolean,
      default: true,
    }
  },
  setup(props) {
    const strinfifyMessage = computed<string>(() => {
      return (typeof props.message === 'string')
        ? props.message
        : errorToMessageString(props.message);
    });

    const isMessageTypeString = computed<boolean>(() => {
      return typeof props.message === 'string'
        // fix
        || isString(get(props.message, 'message', null));
    });

    return {
      errorMessageIcon,
      strinfifyMessage,
      isMessageTypeString,
    };
  },
});
