import { LocalizationDictionary } from '../types';

export default {
  locale: 'English',
  views: {
    // Всплывающие и модальные окна настроек (профиля и приложения)
    // NOTE: объединены несколько компонентов
    settings: {
      user_popover_title: 'Personal Profile',
      settings_modal_title: 'Settings',
      settings_popover_title: '@:views.settings.settings_modal_title',
    },
  },
  components: {
    error_block: {
      btn_reload: 'Try again',
    },

    spinner: {
      loading: 'Loading...',
    },

    //#region Settings (& App services)
    app_settings: {
      label_input_cid: 'Enter your Core ID number',
      label_input_language: 'Language app',
      btn_apply_cid: 'Apply specified number',
      label_network_speed: 'Internet connection speed',
      label_number_recognition: 'Automatic number recognition',
      description_number_recognition: 'Due to external factors, the system does not guarantee 100% recognition of the vehicle registration number',
      label_preentry: 'Pre-entry',
      description_preentry: 'If necessary, you can disable the pre-recording function',
      btn_support_chat: 'Contact support',
      btn_clear_cache: 'Clear cache',
      btn_view_privacy_policy: 'View Policy',

      alert_clear_cache_header: 'Clear cache',
      alert_clear_cache_message: 'Reset will delete all data received from the server.',
      alert_clear_cache_btn_confirm: 'Reset',
    },
    user_settings: {
      fullname_label: 'Shift Administrator',
      btn_exit: 'Exit',
      alert_exit_header: 'Confirm exit',
      alert_exit_message: 'After logging out, you will be redirected to the login page',
      alert_exit_btn_confirm: 'Exit',
    },
    login_form: {
      input_login: 'Login',
      input_password: 'Password',
      btn_login: 'Login',
      btn_login_loading: 'Loading ...',
      message_success_logged_in: 'Successful login!',
      error_logged_in_incorrect_login_or_password: 'Check if the username and password are correct',
      header_error_logged_in_incorrect_login_or_password: 'Invalid credentials',
      header_error_not_authorized: 'Not authorized',
      alert_coreid_header: 'Enter your Core ID',
      alert_coreid_message: 'Core ID can be found in the SMS message, which contains the username and password to enter the application',
      alert_btn_open_settings: 'Enter Core ID',
    },
    //#endregion Settings (& App services)
  },
  composables: {
    alert: {
      confirm_default_btn_cancel: 'Cancel',
      confirm_default_btn_confirm: 'Confirm',
    },

    visit_timings: {
      progress_text_wait: 'Waiting',
      progress_text_completed: 'Completed',
      progress_text_time_over: 'Time is up',
      progress_text_time_left: '~ {left} left',
    },

    visit_badges: {
      label_closed: 'Closed',
      label_finished: 'Completed',
      label_preentry: 'By appointment',
      label_opened: 'Opened',
      label_canceled: 'Canceled',
      label_paid: 'Paid',
      label_not_paid: 'Not paid',

      // Временно отключен
      label_not_sync: 'Not synchronized',
    },
  },
  share: {
    plur_order: 'orders | order | orders',
    plur_points: 'bonuses | bonus | bonuses',
  }
} as LocalizationDictionary;