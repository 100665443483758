
import { defineComponent, PropType, provide, computed } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: String as PropType<string>,
      required: false,
    }
  },
  setup(props) {
    const selectedTab = computed(() => props.modelValue);
    provide('seletedTab', selectedTab);
  },
});
