
import { computed, defineComponent, getCurrentInstance, reactive, ref } from 'vue';
import virtualKeyboard from '@/directives/virtualKeyboard';
import { useVirtualKeyboard } from '@/composables/useVirtualKeyboard';
import { useStore, useApp } from '@/composables/useApp';
import { useToast } from '@/composables/useToast';
import { isClientError } from '@/repositories';
import { isEmpty } from 'lodash';
import { alertController, isPlatform } from '@ionic/core';
import { useSettingsApp } from '@/composables/useSettingsApp';
import { useI18n } from 'vue-i18n';
import { iOSVersion } from '@/helpers/fixIOS';

export default defineComponent({
  directives: { virtualKeyboard },
  emits: {
    success: null,
    error: null,
  },
  setup(props, { emit }) {
    // Старые версии iOS игнорируют inputmode="none" для них ставится readonly,
    // в месте с виртуальной клавиатурой должно работать корректно
    const inputmodeNoneNotSupported = isPlatform('ios') && (iOSVersion()[0] <= 13);

    const keyboardContainer = ref(null);
    const keyboard = useVirtualKeyboard(keyboardContainer, {
      theme: "hg-theme-core12 hg-layout-numeric",
      layout: {
        default: [
          "1 2 3",
          "4 5 6",
          "7 8 9",
          "{bksp} 0 {clear}"
        ],
      },
      display: {
        '{bksp}': '<ion-icon name="core-left-arrow" style="vertical-align: middle;"></ion-icon>',
        '{clear}': 'X',
      },
      buttonTheme: [
        {
          class: 'hg-button-secondary',
          buttons: '{clear}',
        }
      ],
      onKeyPress(button: string) {
        if (button === '{clear}') {
          keyboard.instance?.clearInput();
        }
      }
    });

    const toast = useToast();
    const store = useStore();
    const { t } = useI18n();
    const app = useApp();
    const { openSettings } = useSettingsApp();
    const instance = getCurrentInstance();
    const form = reactive({
      loading: false,
      data: {
        username: store.user.getCurrentUserName() || '',
        password: '',
      },
    });
    const loginButtonDisabled = computed(() =>
      form.loading
      || isEmpty(form.data.username)
      || isEmpty(form.data.password)
    );

    async function login() {
      if (isEmpty(app.getApiDomain())) {
        alertOpenSettings();
        return;
      }

      form.loading = true;

      try {
        await store.user.login(form.data.username, form.data.password);
        // toast.success(t('components.login_form.message_success_logged_in'));

        emit('success');
      } catch (e) {
        if (isClientError(e) && e.response?.status === 401) {
          toast.error(t('components.login_form.error_logged_in_incorrect_login_or_password'), 3000, {
            header: t('components.login_form.header_error_logged_in_incorrect_login_or_password')
          });
        } else {
          toast.error(e, 3500, {
            header: t('components.login_form.header_error_not_authorized')
          });
        }

        emit('error', e);
      } finally {
        form.loading = false;
      }
    }

    async function alertOpenSettings() {
      const alert = await alertController.create({
        header: t('components.login_form.alert_coreid_header'),
        message: t('components.login_form.alert_coreid_message'),
        buttons: [
          {
            text: t('components.login_form.alert_btn_open_settings'),
            handler() {
              const settingsButton = getPageSettingsButton();
              if (settingsButton) {
                settingsButton.click();
              } else {
                openSettings();
              }
              
              alert.dismiss();
            }
          }
        ]
      });

      alert.present();
      return alert;
    }

    function getPageSettingsButton(): HTMLElement|null {
      const el = (instance?.vnode.el || null) as HTMLElement|null;
      const page = el?.closest('.ion-page');
      const button = page?.querySelector('[data-action="settings-button"]') as HTMLElement|null;
      return button || null;
    }

    const loginButtonText = computed(() => {
      return (form.loading)
        ? t('components.login_form.btn_login_loading')
        : t('components.login_form.btn_login');
    });

    return {
      keyboardContainer,
      keyboard,
      login,
      form,
      loginButtonDisabled,
      loginButtonText,
      inputmodeNoneNotSupported,
    };
  },
});
